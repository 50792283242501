<template>
    <div>
       <!-- <a-page-header
           class="header_title"
           :title="title"
           @back="() => $router.go(-1)"
       /> -->
        <!-- <a-button @click="editBtn" type="danger" style="margin-top: 15px;margin-left: 10px;">
            {{$t('product.browse.btn_1')}}
        </a-button> -->
        <div style="width:100%;height:35px;">&nbsp;</div>
       <iframe :src="src" style="width:100%;height: 88vh;border: 0px;margin: 0;padding: 0;"></iframe>

       <div style="position: fixed;bottom: 15px;right: 250px;" v-show="isShow">
            <a-button @click="clickBtn" type="danger" style="margin-top: 15px;margin-left: 10px;">
                {{$t('product.browse.btn_2')}}
            </a-button>
       </div>
       <div style="position: fixed;bottom: 15px;right: 15px;" v-show="isShow2">
            <a-button @click="clickBtn2" style="margin-top: 15px;margin-left: 10px;background-color: #ff105426;">
                {{$t('product.browse.btn_3')}}
            </a-button>
       </div><div style="position: fixed;bottom: 15px;right: 250px;" v-show="isShow3">
            <a-button @click="clickBtn3" style="margin-top: 15px;margin-left: 10px;">
                {{$t('product.browse.btn_4')}}
            </a-button>
       </div>
    </div>
</template>
<script>
import Cookies from 'js-cookie';
import {
    getStatus,
    browseSetSubmit,
    browseSetSubmit2,
} from "@/network/products2";

export default {
    name: 'NodeprojectProducts',
    components: { Cookies },
    directives: { },
    data() {
        return {
            title:this.$t('form.btn_back'),
            src:"https://labs.datahunt.app/brands/makerportal.html?maker_id="+Cookies.get('maker_id'),
            // src:"http://makeradmin.net/brands/makerportal.html?maker_id="+Cookies.get('maker_id'),
            isShow:false,
            isShow2:true,
            isShow3:false,
        }
    },
    created(){
        getStatus().then(res=>{
            if(res.code==0){
                if(res.data.status==0){
                    this.isShow = true;
                }
                if(res.data.status==2){
                    //this.isShow2 = true;
                    this.isShow3 = true;
                }
            }
        })
    },
    mounted() {

    },
    computed:{
     
    },
    methods: {
        //this.$router.push("/companyprofile");
        clickBtn(){
            browseSetSubmit().then(res=>{
                if(res.code==0){
                    this.isShow = false;
                    //this.isShow2 = true;
                    this.isShow3 = true;
                    this.$message.success(res.msg)
                    // this.$router.push("/products2/browse");
                }else{
                    this.$message.error(res.msg)
                }
            });
        },
        clickBtn2(){
            window.open('https://labs.datahunt.app/brands/');
        },
        clickBtn3(){
            browseSetSubmit2().then(res=>{
                if(res.code==0){
                    this.isShow = true;
                    // this.isShow2 = false;
                    this.isShow3 = false;
                    
                    this.$message.success(res.msg)
                    // this.$router.push("/products2/browse");
                }else{
                    this.$message.error(res.msg)
                }
            });
        },
    },
};
</script>